<template>
  <div>
    <OAlert v-if="!isSuccess" variant="info">
      <strong>
        Sending duplicate transactions to the same {{ order.payin_currency }} address is strongly discouraged. The
        second transaction may not be accepted, risking potential loss of funds.
      </strong>
    </OAlert>

    <div style="display: flex; justify-content: center;">
      <img v-if="isSuccess" width="75" src="../assets/order/ok.svg">
      <img v-else width="75" src="../assets/order/pending.svg">
    </div>

    <div style="display: flex; justify-content: center; margin-top: 5px;">
      <div class="order-status">{{ statusMessage }}</div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div>Amount:</div>
      <div><strong>{{ order.payin_amount }} {{ order.payin_currency }}</strong></div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 5px;">
      <div style="word-break: break-all;">Order ID:</div>
      <div>
        {{ order.client_order_id }}
        <img @click="doCopyOrderId()" width="16" class="copy2clipboard"
             src="../assets/copy2clipboard.svg">
        <div v-if="showCopyOrderId" class="p-toast">Copied!</div>
      </div>
    </div>

    <div v-if="order.txid" style="display: flex; justify-content: space-between; margin-top: 5px;">
      <div>Transaction:</div>
      <div>
        {{ shortenString(order.txid, 16) }}
        <img @click="doCopyTxId()" width="16" class="copy2clipboard"
             src="../assets/copy2clipboard.svg">
        <div v-if="showCopyTxId" class="p-toast">Copied!</div>
      </div>
    </div>

    <div v-if="isSuccess" style="margin-top: 20px;">
      <OButton
        to="/"
        theme="primary"
        type="button"
      >
        HOME
      </OButton>
    </div>

  </div>
</template>

<script>

import OButton from '@/objects/OButton.vue'
import OAlert from '@/objects/OAlert.vue'

export default {
  name: 'CSwitcherePayment',
  components: { OAlert, OButton },
  props: {
    order: {
      type: Object, required: true
    }
  },
  data: () => (
    {
      showCopyTxId: false,
      showCopyOrderId: false
    }
  ),
  computed: {
    isSuccess () {
      return this.order.status === 'running' || this.order.status === 'paid'
    },
    statusMessage () {
      if (this.isSuccess) {
        return 'Success'
      }
      return 'Waiting for payment'
    }
  },
  methods: {
    doCopyOrderId () {
      this.$copyText(this.order.client_order_id)
      this.showCopyOrderId = true
      let self = this
      setTimeout(function () {
        self.showCopyOrderId = false
      }, 1000)
    },
    doCopyTxId () {
      this.$copyText(this.order.txid)
      this.showCopyTxId = true
      let self = this
      setTimeout(function () {
        self.showCopyTxId = false
      }, 1000)
    },
    shortenString (str, maxLength) {
      if (str.length <= maxLength) {
        return str // Return the original string if it's already shorter than the maxLength
      }
      const ellipsis = '...'
      const firstChars = str.substring(0, maxLength / 2)
      const lastChars = str.substring(str.length - maxLength / 2)
      return firstChars + ellipsis + lastChars
    }
  }
}
</script>

<style lang="scss">

.copy2clipboard {
  cursor: pointer;
}

.order-status {
  font-weight: bold;
  font-size: 1.5em;
}

.p-toast {
  position: absolute;
  margin-top: -30px;
  margin-left: -30px;
  border-radius: 4px;
  background-color: #333;
  padding: 4px;
  color: #ffffff;
  font-size: 0.8em;
}

</style>
