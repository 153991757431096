<template>
  <l-default>
    <OTitle>Status</OTitle>

    <CVerifyEmail v-if="!isActive"></CVerifyEmail>

    <div v-if="isActive" class="u-mb-3">
      <div v-if="!order">
        <OForm>
          <div class="c-grid__item">
            <OFormError :message="error"></OFormError>
          </div>
        </OForm>
      </div>
      <div v-else>
        <CSwitchereOrder :order="order"/>
      </div>
    </div>
  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapGetters } from 'vuex'
import CVerifyEmail from '../../components/CVerifyEmail'
import OFormError from '@/objects/forms/OFormError.vue'
import OForm from '@/objects/forms/OForm.vue'
import CSwitchereOrder from '@/components/CSwitchereOrder.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PageCreditCardOrder',
  components: {
    CSwitchereOrder,
    OForm,
    OFormError,
    LDefault,
    OTitle,
    CVerifyEmail
  },
  data: function () {
    return {
      error: '',
      order: null,
      interval: null
    }
  },
  computed: {
    ...mapGetters({
      credits: 'getCredits',
      isActive: 'isActive',
      email: 'getEmail'
    }),
    parameters: function () {
      return this.$route.query
    }
  },
  methods: {
    async load () {
      if (this.order && this.order.status !== 'pending') {
        clearInterval(this.interval)
      }
      const api = await getApi()
      const result = await api.getSwitchereOrder({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        id: this.parameters.id
      })
      if (result.error) {
        this.error = result.message
        return false
      }
      this.order = result.data
    },
    showLoader () {
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: '#f00',
        width: 125,
        height: 125
      })
    },
    hideLoader () {
      this.loader.hide()
    }
  },
  mounted () {
    let self = this
    self.load()
    this.interval = setInterval(function () {
      if (this.order && this.order.status !== 'pending') {
        clearInterval(this.interval)
      } else {
        self.load()
      }
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss">
h2 {
  color: $primary;
}

.c-grid {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 1rem;
}

.c-grid__title {
  margin: 0;
  padding: 0.25em 0;
  text-transform: uppercase;
}

.c-grid__item {
  margin: 0 0 0.5rem;
}

.u-ml-auto {
  margin-left: auto;
}

.u-mb-3 {
  margin-bottom: 1.5rem;
}
</style>
